:root {
  --primary-color: white;
}

.markdown a {
  color: var(--primary-color);
}

a:has(sup) {
  text-decoration: none;
}

.markdown p {
  overflow-wrap: break-word;
}

.promotion-slide {
  position: relative;
}

.slide-button {
  box-sizing: border-box;
  position: absolute;
  height: 40%;
  top: 30%;
  z-index: 999;
  background-color: transparent;
  border: none;
  opacity: 0;
  transition: opacity, background-color 0.1s;
  cursor: pointer;
}

.slide-button:hover, .promotion-slide:hover > .slide-button {
  opacity: 100%;
}

.slide-button:hover {
  background-color: #c0c0c044;
}

.slide-button > svg {
  filter:
          drop-shadow(1px 0px 0px #ffffff99)
          drop-shadow(0px 1px 0px #ffffff99)
          drop-shadow(-1px 0px 0px #ffffff99)
          drop-shadow(0px -1px 0px #ffffff99)
  ;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

@media (pointer: coarse) {
  .slide-button {
    opacity: 100%;
  }
}

.slide-viewer {
  position: relative;
  margin-bottom: 1em;
}

.dot-holder {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.2em;
  position: absolute;
  bottom: -1em;
  z-index: 999;
}

.indicator-dot {
  cursor: pointer;
  user-select: none;
}